function slugDecoder(slug) {
  let venue = slug.split('-')[0];
  return venue;
}

function venueDecoder(title) {
  let venue = title.split('@')[1];
  if (!venue) {
    return { title: title };
  } else {
    venue = venue.toLowerCase();
  }
  if (venue.match(/vagalume/)) {
    return { slug: '/tulum-clubs/vagalume-tulum', title: 'Vagalume Tulum' };
  }
  if (venue.match(/mia/)) {
    return { slug: '/tulum-clubs/mia-beach-club-tulum', title: 'Mia Tulum' };
  }
  if (venue.match(/kanan/)) {
    return { slug: '/tulum-clubs/kanan-beach-club-tulum', title: 'Kanan' };
  }
  if (venue.match(/tantra/)) {
    return { slug: '/tulum-clubs/tantra-tulum', title: 'Tantra' };
  }
  if (venue.match(/meze/)) {
    return { slug: '/tulum-clubs/meze-tulum', title: 'Meze Tulum' };
  }
  if (venue.match(/buuts/)) {
    return { slug: '/tulum-clubs/buuts-ha-tulum', title: 'Buuts Ha' };
  }
  if (venue.match(/bagatelle/)) {
    return { slug: '/tulum-clubs/bagatelle-tulum', title: 'Bagatelle' };
  }
  if (venue.match(/papaya/)) {
    return { slug: '/tulum-clubs/papaya-playa', title: 'Papaya Playa Project' };
  }
  if (venue.match(/gitano beach/)) {
    return { slug: '/tulum-clubs/gitano-beach-tulum', title: 'Gitano Beach' };
  }
  if (venue.match(/gitano/)) {
    return { slug: '/tulum-clubs/gitano-tulum', title: 'Gitano Jungle' };
  }
  if (venue.match(/mayan monkey/)) {
    return { slug: '/tulum-clubs/mayan-monkey-tulum', title: 'Mayan Monkey' };
  }
  if (venue.match(/bonbonniere/)) {
    return { slug: '/tulum-clubs/bonbonniere-tulum', title: 'Bonbonniere' };
  }
  if (venue.match(/confessions/)) {
    return { slug: '/tulum-clubs/confessions-tulum', title: 'Confessions' };
  }
  if (venue.match(/rosa negra/)) {
    return { slug: '/tulum-clubs/rosa-negra-tulum', title: 'Rosa Negra' };
  }
  if (venue.match(/taboo tulum/)) {
    return { slug: '/tulum-clubs/taboo-tulum', title: 'Taboo Tulum' };
  }
  if (venue.match(/ilios/)) {
    return {
      slug: '/tulum-clubs/ilios-restaurant-tulum',
      title: 'ILIOS Restaurant',
    };
  }
  if (venue.match(/balabamba/)) {
    return { slug: '/tulum-clubs/balabamba-tulum', title: 'Balabamba' };
  }
  if (venue.match(/zamna/)) {
    return { slug: '/brands/zamna-tulum', title: 'Zamna Tulum' };
  }
  if (venue.match(/karma/)) {
    return { slug: '/brands/karma-restaurant-tulum', title: 'Karma' };
  }
  if (venue.match(/oaxaca/)) {
    return {
      slug: '/event/tierra-de-los-muertos-festival-set-underground-oaxaca-1-november-2024',
      title: 'Oaxaca',
    };
  }
  if (venue.match(/chambao/)) {
    return { slug: '/tulum-clubs/chambao-tulum', title: 'Chambao Tulum' };
  }
  if (venue.match(/funky/)) {
    return { slug: '/tulum-clubs/funky-geisha-tulum', title: 'Funky Geisha' };
  }
  if (venue.match(/bestiario/)) {
    return { slug: '/tulum-clubs/bestiario-tulum', title: 'Bestiario Tulum' };
  }
  if (venue.match(/parole/)) {
    return { slug: '/tulum-clubs/parole-tulum', title: 'Parole Tulum' };
  }
  if (venue.match(/mamazzita/)) {
    return { slug: '/tulum-clubs/mamazzita-tulum', title: 'Mamazzita Tulum' };
  }
  if (venue.match(/tora/)) {
    return { slug: '/tulum-clubs/tora-tulum', title: 'Tora Tulum' };
  }
  if (venue.match(/coco-bongo/)) {
    return { slug: '/cancun-clubs/coco-bongo-cancun', title: 'Coco Bongo Cancun' };
  }
  return { title: venue };
}

function brandDecoder(title) {
  let brand = title.toLowerCase();
  if (brand.match(/vagalume sessions/)) {
    return { slug: 'vagalume-sessions-tulum', title: 'Vagalume Sessions' };
  }
  if (brand.match(/mystical/)) {
    return { slug: 'mystical-sunset-tulum', title: 'Mystical Experiences' };
  }
  if (brand.match(/summerians/)) {
    return { slug: 'summerians-ibiza-tulum', title: 'Summerians' };
  }
  if (brand.match(/ritmic/)) {
    return { slug: 'ritmic-vagalume-tulum', title: 'Ritmic' };
  }
  if (brand.match(/sos/)) {
    return { slug: 'sos-save-our-souls-tulum', title: 'SOS' };
  }
  if (brand.match(/sinner/)) {
    return { slug: 'sinner-tulum', title: 'Sinner' };
  }
  if (brand.match(/sundaze/)) {
    return { slug: 'sundaze-vagalume-tulum', title: 'Sundaze' };
  }
  if (brand.match(/piknik/)) {
    return { slug: 'piknik-tulum', title: 'Piknik' };
  }
  if (brand.match(/ underground/)) {
    return { slug: 'set-underground-tulum', title: 'SET Underground' };
  }
  if (brand.match(/gypsy disco/)) {
    return { slug: 'gypsy-disco-gitano-tulum', title: 'Gypsy Disco' };
  }
  if (brand.match(/ancestral/)) {
    return { slug: 'ancestral-soul-tulum', title: 'Ancestral Soul' };
  }
  if (brand.match(/ten ibiza/)) {
    return { slug: 'ten-ibiza-tulum', title: 'Ten Ibiza' };
  }
  if (brand.match(/ants.+?tulum/)) {
    return { slug: 'ants-tulum', title: 'ANTS' };
  }
  if (brand.match(/afterlife/)) {
    return { slug: 'afterlife-tulum', title: 'AFTERLIFE' };
  }
  if (brand.match(/circoloco/)) {
    return { slug: 'circoloco-tulum', title: 'CIRCOLOCO' };
  }
  if (brand.match(/core.+?tomorrowland/)) {
    return { slug: 'core-tulum', title: 'CORE' };
  }
  if (brand.match(/keinemusik/)) {
    return { slug: 'keinemusik-tulum', title: 'keinemusik' };
  }
  if (brand.match(/zamna/)) {
    return { slug: 'zamna-tulum', title: 'ZAMNA' };
  }
  if (brand.match(/day.+?zero/)) {
    return { slug: 'day-zero-tulum', title: 'DAY ZERO' };
  }
  if (brand.match(/dpm/)) {
    return { slug: 'dpm-global-tulum', title: 'DPM GLOBAL' };
  }
}

export { brandDecoder, venueDecoder, slugDecoder };
